import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';

const heroSliderClass = '.js-hero-slider';

// Swiper init
const heroSlider = new Swiper(heroSliderClass, {
    modules: [Pagination, Autoplay],
    slidesPerView: 1,
    loop: true,
    autoplay: {
        delay: 4000,
    },
    pagination: {
        el: '.js-hero-slider-pagination',
        clickable: true,
        bulletClass: 'hero-slider__pagination-item',
        bulletActiveClass: 'is-active',
    },
});
